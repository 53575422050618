import React, { useEffect } from 'react';
import { graphql, Link, navigate } from 'gatsby';
import { motion } from 'framer-motion';
import Img from 'gatsby-image';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';

import styled, { mq } from 'src/components/styled';
import Typography from 'src/components/Typography';
import SEO from 'src/components/SEO';
import { IconButton } from 'src/components';

const MainContainer = styled(motion.div)`
  background-color: ${(p) => p.bg};
`;

const ProjectBackground = styled(motion.div)`
  width: 100%;
  background-image: linear-gradient(30deg, hsl(0, 100%, 45%) 0%, hsla(0, 100%, 100%, 0) 100%);
  background-blend-mode: luminosity;
  background-color: ${(p) => p.bg};
`;

const HeadingContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  padding: 0 32px;
  margin: 0 auto;
`;

const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 16px 0;
`;
const ProjectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${mq({
    height: ['250px', '340px', '390px', '520px'],
  })}
`;

const ProjectTitle = styled(Typography)`
  flex: 1;
  color: #fff;
  ${mq({
    'font-size': ['8vw', null, null, null, 96],
  })}

  font-weight: 700;
  margin-bottom: 16px;
`;

const Mockup = styled(motion.div)`
  padding-top: 32px;
  ${mq({
    height: ['320px', '380px', '440px', '572px'],
  })}
  flex: 1;
`;

const ContentContainer = styled(motion.div)`
  max-width: 1200px;
  width: 100%;
  padding: 72px 32px;
  margin: 0 auto;
  color: #fff;
`;

const SmallTitle = styled(Typography)`
  color: #ccc;
  text-transform: uppercase;
  margin: 0;
  font-size: 20px;
  letter-spacing: 1px;
`;

const Title = styled(Typography)`
  margin-bottom: 12px;
`;

const ShortSummary = styled(Typography)`
  font-size: 24px;
  font-weight: 300;
  color: #bbb;
`;

const DescriptionRow = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
  grid-gap: 40px;
`;

const DetailsColumn = styled.div``;
const DetailRow = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
const DetailTitle = styled(Typography)`
  min-width: 120px;
  font-size: 18px;
  font-weight: 400;
  color: #f1f1f1;
  margin: 0;
`;
const DetailText = styled(Typography)`
  font-size: 18px;
  font-weight: 300;
  margin: 0;
`;
const SummaryColumn = styled(motion.div)``;
const SummaryText = styled(Typography)`
  font-size: 18px;
  color: #dfdfdf;
  font-weight: 300;
  margin: 0;
  white-space: pre-wrap;
`;

const NextProjectContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  padding: 72px 32px 48px;
  margin: 0 auto;
  text-align: right;
`;

const NextProjectTitle = styled(Typography)`
  color: #ccc;
  text-transform: uppercase;
  margin: 0;
  font-size: 16px;
  letter-spacing: 1px;
`;

const NextProjectName = styled(Typography)`
  color: #fff;
  margin: 0;
  font-size: 24px;
`;

const NextProjectLink = styled(Link)`
  pointer-events: auto;
  color: inherit;
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
`;

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

const thumbnailVariants = {
  initial: { scale: 0.9, opacity: 0 },
  enter: { scale: 1, opacity: 1, transition },
  exit: {
    scale: 0.5,
    opacity: 0,
    transition: { ...transition },
  },
};

const mainVariant = {
  initial: { opacity: 0 },
  enter: { opacity: 1, transition },
  exit: {
    opacity: 0,
    transition: { ...transition },
  },
};

const letterContainerVariants = {
  initial: {},
  enter: { transition: { staggerChildren: 0.12 } },
  exit: {},
};

const letterVariants = {
  initial: { opacity: 0 },
  enter: { opacity: 1, transition },
  exit: {
    opacity: 0,
    transition: { ...transition },
  },
};

const textBlockVariants = {
  initial: ([axis, value]) => ({ opacity: 0, [axis]: value }),
  enter: ([axis]) => ({ opacity: 1, [axis]: 0, transition }),
  exit: ([axis, value]) => ({
    opacity: 0,
    [axis]: value,
    transition: { ...transition },
  }),
};

export default function Project({ data }: { data: { projectsJson: GatsbyTypes.ProjectsJson } }) {
  const project = data.projectsJson;
  return (
    <>
      <SEO title={project.title} />
      <MainContainer bg={project.backgroundColor} variants={mainVariant}>
        <ProjectBackground bg={project.color}>
          <HeadingContainer>
            <NavigationContainer>
              <Typography as="h2" style={{ marginBottom: 0 }}>
                <IconButton
                  ariaLabel="Go back"
                  iconComponent={IoIosArrowRoundBack}
                  onClick={() => navigate('/')}
                  iconColor="#fff"
                  animateDirOnClick={-15}
                />
              </Typography>
            </NavigationContainer>
            <ProjectContainer>
              <motion.div variants={letterContainerVariants} style={{ alignSelf: 'flex-end' }}>
                {project.title?.split('').map((letter, index) => (
                  <motion.div
                    key={`title-${index}`}
                    style={{
                      position: 'relative',
                      display: 'inline-block',
                    }}
                    variants={letterVariants}
                  >
                    <ProjectTitle as="h1">{letter}</ProjectTitle>
                  </motion.div>
                ))}
              </motion.div>

              <Mockup variants={thumbnailVariants}>
                <Img
                  fluid={project.image.childImageSharp.fluid}
                  style={{ maxHeight: '100%', pointerEvents: 'none' }}
                  imgStyle={{ objectFit: 'contain', objectPosition: 'right' }}
                  draggable={false}
                />
              </Mockup>
            </ProjectContainer>
          </HeadingContainer>
        </ProjectBackground>

        <ContentContainer>
          <motion.div variants={textBlockVariants} custom={['y', -10]}>
            <SmallTitle as="h3">{project.longTitle}</SmallTitle>
            <Title as="h2">{project.subtitle}</Title>
            <ShortSummary as="h4">{project.shortSummary}</ShortSummary>
          </motion.div>

          <DescriptionRow variants={textBlockVariants} custom={['x', -10]}>
            <DetailsColumn>
              <DetailRow>
                <DetailTitle>Platform:</DetailTitle>
                <DetailText>{project.platform}</DetailText>
              </DetailRow>
              <DetailRow>
                <DetailTitle>Role:</DetailTitle>
                <DetailText>{project.role}</DetailText>
              </DetailRow>
              <DetailRow>
                <DetailTitle>Tech:</DetailTitle>
                <DetailText>{project.tech}</DetailText>
              </DetailRow>
              <DetailRow>
                <DetailTitle>Team:</DetailTitle>
                <DetailText>{project.team}</DetailText>
              </DetailRow>
            </DetailsColumn>

            <SummaryColumn variants={textBlockVariants} custom={['x', 20]}>
              <SummaryText>{project.fullSummary}</SummaryText>
            </SummaryColumn>
          </DescriptionRow>
        </ContentContainer>
        <NextProjectContainer>
          <hr
            style={{
              height: 1,
              border: 'none',
              color: '#ccc',
              backgroundColor: '#ccc',
              marginBottom: 16,
            }}
          />
          <NextProjectTitle as="h1">NEXT PROJECT</NextProjectTitle>

          <NextProjectName as="h2">
            <IconButton
              ariaLabel={`Go to next project, ${project.nextProject.title}`}
              iconComponent={IoIosArrowRoundForward}
              onClick={() => navigate(project.nextProject.link)}
              iconColor="#fff"
              renderLabel={() => <NextProjectLink>{project.nextProject.title}</NextProjectLink>}
              animateDirOnClick={15}
            />
          </NextProjectName>
        </NextProjectContainer>
      </MainContainer>
    </>
  );
}

export const query = graphql`
  query($slug: String!) {
    projectsJson(slug: { eq: $slug }) {
      title
      longTitle
      subtitle
      shortSummary
      fullSummary
      platform
      role
      tech
      team
      color
      backgroundColor
      shadowColor
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      nextProject {
        title
        link
      }
    }
  }
`;
